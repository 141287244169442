body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body>iframe {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    position: relative;
}

#secdimrouter>div {
    height: 100%;
}

#root {
    height: 100%;
}

.cm-editor,
.cm-gutters {
    background-color: transparent;
    padding-left: 20px;
    border: none;
}